import { combineReducers } from 'redux';
import {
    HIDE_LIVE_STREAM_DELETE_DIALOG,
    LIVE_STREAM_DESCRIPTION_SET,
    LIVE_STREAM_ID_SET,
    LIVE_STREAM_KEY_SET,
    LIVE_STREAM_SCHEDULE_END_DATE_SET,
    LIVE_STREAM_SCHEDULE_START_DATE_SET,
    LIVE_STREAM_SETUP_DIALOG_HIDE,
    LIVE_STREAM_SETUP_DIALOG_SHOW,
    LIVE_STREAM_SETUP_NAME_SET,
    LIVE_STREAM_SETUP_NODE_SET,
    LIVE_STREAM_THUMBNAIL_SET,
    LIVE_STREAM_VISIBILITY_SET,
    SHOW_LIVE_STREAM_DELETE_DIALOG,
    TV_LIVE_STREAM_ADD_ERROR,
    TV_LIVE_STREAM_ADD_IN_PROGRESS,
    TV_LIVE_STREAM_ADD_SUCCESS,
    TV_LIVE_STREAM_DELETE_SUCCESS,
    TV_LIVE_STREAM_FETCH_ERROR,
    TV_LIVE_STREAM_FETCH_IN_PROGRESS,
    TV_LIVE_STREAM_FETCH_SUCCESS,
    TV_LIVE_STREAM_PUBLISH_ERROR,
    TV_LIVE_STREAM_PUBLISH_IN_PROGRESS,
    TV_LIVE_STREAM_PUBLISH_SUCCESS,
    TV_LIVE_STREAM_UN_PUBLISH_ERROR,
    TV_LIVE_STREAM_UN_PUBLISH_IN_PROGRESS,
    TV_LIVE_STREAM_UN_PUBLISH_SUCCESS,
    TV_LIVE_STREAM_UPDATE_ERROR,
    TV_LIVE_STREAM_UPDATE_IN_PROGRESS,
    TV_LIVE_STREAM_UPDATE_SUCCESS,
    TV_LIVE_STREAM_URL_FETCH_ERROR,
    TV_LIVE_STREAM_URL_FETCH_IN_PROGRESS,
    TV_LIVE_STREAM_URL_FETCH_SUCCESS,
    TV_LIVE_STREAMS_FETCH_ERROR,
    TV_LIVE_STREAMS_FETCH_IN_PROGRESS,
    TV_LIVE_STREAMS_FETCH_SUCCESS,
} from '../../constants/mediaNodes/tv';

const streams = (state = {
    value: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case TV_LIVE_STREAMS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case TV_LIVE_STREAMS_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case TV_LIVE_STREAMS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const streamInfo = (state = {
    value: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case TV_LIVE_STREAM_FETCH_IN_PROGRESS:
    case TV_LIVE_STREAM_UPDATE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case TV_LIVE_STREAM_FETCH_SUCCESS:
    case TV_LIVE_STREAM_UPDATE_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case TV_LIVE_STREAM_FETCH_ERROR:
    case TV_LIVE_STREAM_UPDATE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const addStream = (state = {
    value: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case TV_LIVE_STREAM_ADD_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case TV_LIVE_STREAM_ADD_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case TV_LIVE_STREAM_ADD_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const url = (state = {
    value: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case TV_LIVE_STREAM_URL_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case TV_LIVE_STREAM_URL_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case TV_LIVE_STREAM_URL_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const publish = (state = {
    value: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case TV_LIVE_STREAM_PUBLISH_IN_PROGRESS:
    case TV_LIVE_STREAM_UN_PUBLISH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case TV_LIVE_STREAM_PUBLISH_SUCCESS:
    case TV_LIVE_STREAM_UN_PUBLISH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case TV_LIVE_STREAM_PUBLISH_ERROR:
    case TV_LIVE_STREAM_UN_PUBLISH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

// setup live stream
const addLiveStreamDialog = (state = {
    open: false,
}, action) => {
    switch (action.type) {
    case LIVE_STREAM_SETUP_DIALOG_SHOW:
        return {
            open: true,
        };
    case LIVE_STREAM_SETUP_DIALOG_HIDE:
    case TV_LIVE_STREAM_ADD_SUCCESS:
        return {
            open: false,
        };
    default:
        return state;
    }
};

const deleteLiveStreamDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case SHOW_LIVE_STREAM_DELETE_DIALOG:
        return {
            ...state,
            open: true,
            value: action.value,
        };
    case HIDE_LIVE_STREAM_DELETE_DIALOG:
    case TV_LIVE_STREAM_DELETE_SUCCESS:
        return {
            ...state,
            open: false,
            value: {},
        };
    default:
        return state;
    }
};

const streamName = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case LIVE_STREAM_SETUP_NAME_SET:
        return {
            value: action.value,
        };
    case LIVE_STREAM_SETUP_DIALOG_HIDE:
        return {
            value: '',
        };
    default:
        return state;
    }
};

const streamNode = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case LIVE_STREAM_SETUP_NODE_SET:
        return {
            value: action.value,
        };
    case LIVE_STREAM_SETUP_DIALOG_HIDE:
        return {
            value: '',
        };
    default:
        return state;
    }
};

const streamDescription = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case LIVE_STREAM_DESCRIPTION_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case LIVE_STREAM_SETUP_DIALOG_HIDE:
        return {
            value: '',
            valid: true,
        };
    default:
        return state;
    }
};

const streamThumbnail = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case LIVE_STREAM_THUMBNAIL_SET:
        return {
            value: action.value,
        };
    case LIVE_STREAM_SETUP_DIALOG_HIDE:
        return {
            value: '',
        };
    default:
        return state;
    }
};

const streamScheduleStartDate = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case LIVE_STREAM_SCHEDULE_START_DATE_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case LIVE_STREAM_SETUP_DIALOG_HIDE:
        return {
            value: '',
            valid: true,
        };
    default:
        return state;
    }
};

const streamScheduleEndDate = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case LIVE_STREAM_SCHEDULE_END_DATE_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case LIVE_STREAM_SETUP_DIALOG_HIDE:
        return {
            value: '',
            valid: true,
        };
    default:
        return state;
    }
};

const streamVisibility = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case LIVE_STREAM_VISIBILITY_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const inputStreamId = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case LIVE_STREAM_ID_SET:
        return {
            ...state,
            value: action.value,
        };
    case TV_LIVE_STREAM_FETCH_SUCCESS:
        return {
            ...state,
            value: action.value && action.value._id,
        };

    default:
        return state;
    }
};

const inputStreamKey = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case LIVE_STREAM_KEY_SET:
        return {
            ...state,
            value: action.value,
        };
    case TV_LIVE_STREAM_FETCH_SUCCESS:
        return {
            ...state,
            value: action.value && action.value.stream_key,
        };

    default:
        return state;
    }
};

export default combineReducers({
    streams,
    streamInfo,
    addStream,
    url,
    publish,
    addLiveStreamDialog,
    deleteLiveStreamDialog,
    streamName,
    streamNode,
    streamDescription,
    streamThumbnail,
    streamScheduleStartDate,
    streamScheduleEndDate,
    streamVisibility,
    inputStreamId,
    inputStreamKey,
});
