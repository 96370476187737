export const TV_LIVE_STREAMS_FETCH_SUCCESS = 'TV_LIVE_STREAMS_FETCH_SUCCESS';
export const TV_LIVE_STREAMS_FETCH_IN_PROGRESS = 'TV_LIVE_STREAMS_FETCH_IN_PROGRESS';
export const TV_LIVE_STREAMS_FETCH_ERROR = 'TV_LIVE_STREAMS_FETCH_ERROR';

export const TV_LIVE_STREAM_FETCH_SUCCESS = 'TV_LIVE_STREAM_FETCH_SUCCESS';
export const TV_LIVE_STREAM_FETCH_IN_PROGRESS = 'TV_LIVE_STREAM_FETCH_IN_PROGRESS';
export const TV_LIVE_STREAM_FETCH_ERROR = 'TV_LIVE_STREAM_FETCH_ERROR';

export const TV_LIVE_STREAM_ADD_SUCCESS = 'TV_LIVE_STREAM_ADD_SUCCESS';
export const TV_LIVE_STREAM_ADD_IN_PROGRESS = 'TV_LIVE_STREAM_ADD_IN_PROGRESS';
export const TV_LIVE_STREAM_ADD_ERROR = 'TV_LIVE_STREAM_ADD_ERROR';

export const TV_LIVE_STREAM_UPDATE_SUCCESS = 'TV_LIVE_STREAM_UPDATE_SUCCESS';
export const TV_LIVE_STREAM_UPDATE_IN_PROGRESS = 'TV_LIVE_STREAM_UPDATE_IN_PROGRESS';
export const TV_LIVE_STREAM_UPDATE_ERROR = 'TV_LIVE_STREAM_UPDATE_ERROR';

export const TV_LIVE_STREAM_DELETE_SUCCESS = 'TV_LIVE_STREAM_DELETE_SUCCESS';
export const TV_LIVE_STREAM_DELETE_IN_PROGRESS = 'TV_LIVE_STREAM_DELETE_IN_PROGRESS';
export const TV_LIVE_STREAM_DELETE_ERROR = 'TV_LIVE_STREAM_DELETE_ERROR';

export const SHOW_LIVE_STREAM_DELETE_DIALOG = 'SHOW_LIVE_STREAM_DELETE_DIALOG';
export const HIDE_LIVE_STREAM_DELETE_DIALOG = 'HIDE_LIVE_STREAM_DELETE_DIALOG';

export const TV_LIVE_STREAM_URL_FETCH_SUCCESS = 'TV_LIVE_STREAM_URL_FETCH_SUCCESS';
export const TV_LIVE_STREAM_URL_FETCH_IN_PROGRESS = 'TV_LIVE_STREAM_URL_FETCH_IN_PROGRESS';
export const TV_LIVE_STREAM_URL_FETCH_ERROR = 'TV_LIVE_STREAM_URL_FETCH_ERROR';

export const TV_LIVE_STREAM_PUBLISH_SUCCESS = 'TV_LIVE_STREAM_PUBLISH_SUCCESS';
export const TV_LIVE_STREAM_PUBLISH_IN_PROGRESS = 'TV_LIVE_STREAM_PUBLISH_IN_PROGRESS';
export const TV_LIVE_STREAM_PUBLISH_ERROR = 'TV_LIVE_STREAM_PUBLISH_ERROR';

export const TV_LIVE_STREAM_UN_PUBLISH_SUCCESS = 'TV_LIVE_STREAM_UN_PUBLISH_SUCCESS';
export const TV_LIVE_STREAM_UN_PUBLISH_IN_PROGRESS = 'TV_LIVE_STREAM_UN_PUBLISH_IN_PROGRESS';
export const TV_LIVE_STREAM_UN_PUBLISH_ERROR = 'TV_LIVE_STREAM_UN_PUBLISH_ERROR';

export const LIVE_STREAM_SETUP_DIALOG_SHOW = 'LIVE_STREAM_SETUP_DIALOG_SHOW';
export const LIVE_STREAM_SETUP_DIALOG_HIDE = 'LIVE_STREAM_SETUP_DIALOG_HIDE';

export const LIVE_STREAM_SETUP_NAME_SET = 'LIVE_STREAM_SETUP_NAME_SET';
export const LIVE_STREAM_SETUP_NODE_SET = 'LIVE_STREAM_SETUP_NODE_SET';
export const LIVE_STREAM_ID_SET = 'LIVE_STREAM_ID_SET';
export const LIVE_STREAM_KEY_SET = 'LIVE_STREAM_KEY_SET';
export const LIVE_STREAM_DESCRIPTION_SET = 'LIVE_STREAM_DESCRIPTION_SET';
export const LIVE_STREAM_THUMBNAIL_SET = 'LIVE_STREAM_THUMBNAIL_SET';
export const LIVE_STREAM_SCHEDULE_START_DATE_SET = 'LIVE_STREAM_SCHEDULE_START_DATE_SET';
export const LIVE_STREAM_SCHEDULE_END_DATE_SET = 'LIVE_STREAM_SCHEDULE_END_DATE_SET';
export const LIVE_STREAM_VISIBILITY_SET = 'LIVE_STREAM_VISIBILITY_SET';
