import {
    ADD_MEDIA_NODE_DIALOG_HIDE,
    ADD_MEDIA_NODE_DIALOG_SHOW,
    ADD_MEDIA_NODE_ERROR,
    ADD_MEDIA_NODE_IN_PROGRESS,
    ADD_MEDIA_NODE_SUCCESS,
    CONNECT_MEDIA_NODE_ERROR,
    CONNECT_MEDIA_NODE_IN_PROGRESS,
    CONNECT_MEDIA_NODE_SUCCESS,
    DELETE_MEDIA_NODE_DIALOG_HIDE,
    DELETE_MEDIA_NODE_DIALOG_SHOW,
    DELETE_MEDIA_NODE_ERROR,
    DELETE_MEDIA_NODE_IN_PROGRESS,
    DELETE_MEDIA_NODE_SUCCESS,
    MEDIA_NODE_ACCESS_TOKEN_FETCH_ERROR,
    MEDIA_NODE_ACCESS_TOKEN_FETCH_IN_PROGRESS,
    MEDIA_NODE_ACCESS_TOKEN_FETCH_SUCCESS,
    MEDIA_NODE_AUTH_TOKEN_SET,
    MEDIA_NODE_DESCRIPTION_SET,
    MEDIA_NODE_DETAILS_FETCH_ERROR,
    MEDIA_NODE_DETAILS_FETCH_IN_PROGRESS,
    MEDIA_NODE_DETAILS_FETCH_SUCCESS,
    MEDIA_NODE_DETAILS_SET,
    MEDIA_NODE_NAME_SET,
    MEDIA_NODE_PROFILE_DETAILS_FETCH_ERROR,
    MEDIA_NODE_PROFILE_DETAILS_FETCH_IN_PROGRESS,
    MEDIA_NODE_PROFILE_DETAILS_FETCH_SUCCESS,
    MEDIA_NODE_STATUS_FETCH_ERROR,
    MEDIA_NODE_STATUS_FETCH_IN_PROGRESS,
    MEDIA_NODE_STATUS_FETCH_SUCCESS,
    MEDIA_NODE_UPLOAD_ACCESS_TOKEN_SET,
    MEDIA_NODE_URL_SET,
    MEDIA_NODES_FETCH_ERROR,
    MEDIA_NODES_FETCH_IN_PROGRESS,
    MEDIA_NODES_FETCH_SUCCESS,
    UPDATE_MEDIA_NODE_DIALOG_HIDE,
    UPDATE_MEDIA_NODE_DIALOG_SHOW,
    UPDATE_MEDIA_NODE_ERROR,
    UPDATE_MEDIA_NODE_IN_PROGRESS,
    UPDATE_MEDIA_NODE_SUCCESS,
    VERIFY_MEDIA_NODE_ERROR,
    VERIFY_MEDIA_NODE_IN_PROGRESS,
    VERIFY_MEDIA_NODE_SUCCESS,
} from '../../constants/mediaNodes';
import { urlAddMediaNode, urlDeleteMediaNode, urlFetchMediaNodeDetails, urlFetchMediaNodes } from '../../constants/url';
import Axios from 'axios';
import { handleErrorMessage } from '../../utils/errorMessages';
import { urlConnectMediaNode, urlFetchMediaNodeAccessToken, urlFetchMediaNodeStatus, urlFetchProfileDetails, urlVerifyMediaNode } from '../../constants/mediaNodes/url';

export const setMediaNodeName = (value) => {
    return {
        type: MEDIA_NODE_NAME_SET,
        value,
    };
};

export const setMediaNodeDescription = (value, valid) => {
    return {
        type: MEDIA_NODE_DESCRIPTION_SET,
        value,
        valid,
    };
};

export const setMediaNodeUrl = (value) => {
    return {
        type: MEDIA_NODE_URL_SET,
        value,
    };
};

export const setMediaNodeAuthToken = (value) => {
    return {
        type: MEDIA_NODE_AUTH_TOKEN_SET,
        value,
    };
};

export const setMediaNodeAccessToken = (value) => {
    return {
        type: MEDIA_NODE_UPLOAD_ACCESS_TOKEN_SET,
        value,
    };
};

export const showAddMediaNodeDialog = (value) => {
    return {
        type: ADD_MEDIA_NODE_DIALOG_SHOW,
        value,
    };
};

export const hideAddMediaNodeDialog = () => {
    return {
        type: ADD_MEDIA_NODE_DIALOG_HIDE,
    };
};

export const showUpdateMediaNodeDialog = (value) => {
    return {
        type: UPDATE_MEDIA_NODE_DIALOG_SHOW,
        value,
    };
};

export const hideUpdateMediaNodeDialog = () => {
    return {
        type: UPDATE_MEDIA_NODE_DIALOG_HIDE,
    };
};

export const showDeleteMediaNodeDialog = (value) => {
    return {
        type: DELETE_MEDIA_NODE_DIALOG_SHOW,
        value,
    };
};

export const hideDeleteMediaNodeDialog = () => {
    return {
        type: DELETE_MEDIA_NODE_DIALOG_HIDE,
    };
};

const fetchMediaNodesInProgress = () => {
    return {
        type: MEDIA_NODES_FETCH_IN_PROGRESS,
    };
};

const fetchMediaNodesSuccess = (value, skip, limit, total) => {
    return {
        type: MEDIA_NODES_FETCH_SUCCESS,
        value,
        skip,
        limit,
        total,
    };
};

const fetchMediaNodesError = (message) => {
    return {
        type: MEDIA_NODES_FETCH_ERROR,
        message,
    };
};

export const fetchMediaNodes = (skip, limit, cb) => (dispatch) => {
    dispatch(fetchMediaNodesInProgress());

    const url = urlFetchMediaNodes(skip, limit);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_of_studio'),
        },
    })
        .then((res) => {
            dispatch(fetchMediaNodesSuccess(res.data && res.data.result,
                skip, limit, res && res.data && res.data.result && res.data.result.count));
            if (cb) {
                cb(res.data && res.data.result, res && res.data && res.data.result && res.data.result.count);
            }
        })
        .catch((error) => {
            const message = handleErrorMessage(error);
            dispatch(fetchMediaNodesError(message));
            if (cb) {
                cb(null);
            }
        });
};

const fetchMediaNodeDetailsInProgress = () => {
    return {
        type: MEDIA_NODE_DETAILS_FETCH_IN_PROGRESS,
    };
};

const fetchMediaNodeDetailsSuccess = (value) => {
    return {
        type: MEDIA_NODE_DETAILS_FETCH_SUCCESS,
        value,
    };
};

const fetchMediaNodeDetailsError = (message) => {
    return {
        type: MEDIA_NODE_DETAILS_FETCH_ERROR,
        message,
    };
};

export const fetchMediaNodeDetails = (id, cb) => (dispatch) => {
    dispatch(fetchMediaNodeDetailsInProgress());

    const url = urlFetchMediaNodeDetails(id);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_of_studio'),
        },
    })
        .then((res) => {
            dispatch(fetchMediaNodeDetailsSuccess(res.data && res.data.result));
            if (cb) {
                cb(res.data && res.data.result);
            }
        })
        .catch((error) => {
            const message = handleErrorMessage(error);
            dispatch(fetchMediaNodeDetailsError(message));
            if (cb) {
                cb(null);
            }
        });
};

export const setMediaNodeDetails = (value) => {
    return {
        type: MEDIA_NODE_DETAILS_SET,
        value,
    };
};

const addMediaNodeInProgress = () => {
    return {
        type: ADD_MEDIA_NODE_IN_PROGRESS,
    };
};

const addMediaNodeSuccess = (value, message) => {
    return {
        type: ADD_MEDIA_NODE_SUCCESS,
        value,
        message,
    };
};

const addMediaNodeError = (message) => {
    return {
        type: ADD_MEDIA_NODE_ERROR,
        message,
    };
};

export const addMediaNode = (data, cb) => (dispatch) => {
    dispatch(addMediaNodeInProgress());

    const url = urlAddMediaNode();
    Axios.post(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_of_studio'),
        },
    })
        .then((res) => {
            dispatch(addMediaNodeSuccess(res.data && res.data.result, 'Media Node Added Successfully'));
            if (cb) {
                cb(res.data && res.data.result);
            }
        })
        .catch((error) => {
            const message = handleErrorMessage(error);
            dispatch(addMediaNodeError(message));
            if (cb) {
                cb(null);
            }
        });
};

const updateMediaNodeInProgress = () => {
    return {
        type: UPDATE_MEDIA_NODE_IN_PROGRESS,
    };
};

const updateMediaNodeSuccess = (value, message) => {
    return {
        type: UPDATE_MEDIA_NODE_SUCCESS,
        value,
        message,
    };
};

const updateMediaNodeError = (message) => {
    return {
        type: UPDATE_MEDIA_NODE_ERROR,
        message,
    };
};

export const updateMediaNode = (id, data, cb) => (dispatch) => {
    dispatch(updateMediaNodeInProgress());

    const url = urlDeleteMediaNode(id);
    Axios.put(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_of_studio'),
        },
    })
        .then((res) => {
            dispatch(updateMediaNodeSuccess(res.data && res.data.result, 'Media Node Updated Successfully'));
            if (cb) {
                cb(res.data && res.data.result);
            }
        })
        .catch((error) => {
            const message = handleErrorMessage(error);
            dispatch(updateMediaNodeError(message));
            if (cb) {
                cb(null);
            }
        });
};

// const fetchChannelInfoInProgress = () => {
//     return {
//         type: FETCH_CHANNEL_INFO_IN_PROGRESS,
//     };
// };

// const fetchChannelInfoSuccess = (value) => {
//     return {
//         type: FETCH_CHANNEL_INFO_SUCCESS,
//         value,
//     };
// };

// const fetchChannelInfoError = (message) => {
//     return {
//         type: FETCH_CHANNEL_INFO_ERROR,
//         message,
//     };
// };

// export const fetchChannelInfo = (id, cb) => (dispatch) => {
//     dispatch(fetchChannelInfoInProgress());

//     const url = urlFetchChannel(id);
//     Axios.get(url, {
//         headers: {
//             Accept: 'application/json, text/plain, */*',
//             Authorization: 'Bearer ' + localStorage.getItem('acToken_of_studio'),
//         },
//     })
//         .then((res) => {
//             dispatch(fetchChannelInfoSuccess(res.data && res.data.result));
//             if (cb) {
//                 cb(res.data && res.data.result);
//             }
//         })
//         .catch((error) => {
//             const message = handleErrorMessage(error);
//             dispatch(fetchChannelInfoError(message));
//             if (cb) {
//                 cb(null);
//             }
//         });
// };

const deleteMediaNodeInProgress = () => {
    return {
        type: DELETE_MEDIA_NODE_IN_PROGRESS,
    };
};

const deleteMediaNodeSuccess = (value, message) => {
    return {
        type: DELETE_MEDIA_NODE_SUCCESS,
        value,
        message,
    };
};

const deleteMediaNodeError = (message) => {
    return {
        type: DELETE_MEDIA_NODE_ERROR,
        message,
    };
};

export const deleteMediaNode = (id, cb) => (dispatch) => {
    dispatch(deleteMediaNodeInProgress());

    const URL = urlDeleteMediaNode(id);
    Axios.delete(URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_of_studio'),
        },
    })
        .then((res) => {
            dispatch(deleteMediaNodeSuccess(res.data, 'Media Node Deleted Successfully'));
            if (cb) {
                cb(res.data);
            }
        })
        .catch((error) => {
            const message = handleErrorMessage(error);
            dispatch(deleteMediaNodeError(message));
            if (cb) {
                cb(null);
            }
        });
};

const connectMediaNodeInProgress = () => {
    return {
        type: CONNECT_MEDIA_NODE_IN_PROGRESS,
    };
};

const connectMediaNodeSuccess = (value) => {
    return {
        type: CONNECT_MEDIA_NODE_SUCCESS,
        value,
    };
};

const connectMediaNodeError = (message) => {
    return {
        type: CONNECT_MEDIA_NODE_ERROR,
        message,
        variant: 'error',
    };
};

export const connectMediaNode = (url, data, cb) => (dispatch) => {
    dispatch(connectMediaNodeInProgress());

    const URL = urlConnectMediaNode(url);
    Axios.post(URL, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            dispatch(connectMediaNodeSuccess(res.data.result));
            cb(res.data.result);
        })
        .catch((error) => {
            const message = handleErrorMessage(error);
            dispatch(connectMediaNodeError(message));

            cb(null);
        });
};

const verifyMediaNodeInProgress = () => {
    return {
        type: VERIFY_MEDIA_NODE_IN_PROGRESS,
    };
};

const verifyMediaNodeSuccess = (message, value) => {
    return {
        type: VERIFY_MEDIA_NODE_SUCCESS,
        message,
        value,
    };
};

const verifyMediaNodeError = (message) => {
    return {
        type: VERIFY_MEDIA_NODE_ERROR,
        message,
        variant: 'error',
    };
};

export const verifyMediaNode = (url, nodeID, userId, data, cb) => (dispatch) => {
    dispatch(verifyMediaNodeInProgress());

    const URL = urlVerifyMediaNode(url, userId);
    Axios.post(URL, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            dispatch(verifyMediaNodeSuccess('Success', res.data && res.data.result['access_token']));
            localStorage.setItem(`acToken_of_studio_media_node_${nodeID}`, res.data && res.data.result['access_token']);
            localStorage.setItem(`rfToken_of_studio_media_node_${nodeID}`, res.data && res.data.result['refresh_token']);
            cb(null);
        })
        .catch((error) => {
            const message = handleErrorMessage(error);
            dispatch(verifyMediaNodeError(message));

            cb(error);
        });
};

const fetchMediaNodeProfileDetailsInProgress = () => {
    return {
        type: MEDIA_NODE_PROFILE_DETAILS_FETCH_IN_PROGRESS,
    };
};

const fetchMediaNodeProfileDetailsSuccess = (value) => {
    return {
        type: MEDIA_NODE_PROFILE_DETAILS_FETCH_SUCCESS,
        value,
    };
};

const fetchMediaNodeProfileDetailsError = (message, url, nodeID) => {
    return {
        type: MEDIA_NODE_PROFILE_DETAILS_FETCH_ERROR,
        message,
        url,
        nodeID,
        variant: 'error',
    };
};

export const fetchMediaNodeProfileDetails = (url, nodeID, cb) => (dispatch) => {
    dispatch(fetchMediaNodeProfileDetailsInProgress());

    const URL = urlFetchProfileDetails(url);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem(`acToken_of_studio_media_node_${nodeID}`),
        },
    })
        .then((res) => {
            dispatch(fetchMediaNodeProfileDetailsSuccess(res.data && res.data.result));
            if (cb) {
                cb(null);
            }
        })
        .catch((error) => {
            const message = handleErrorMessage(error);
            dispatch(fetchMediaNodeProfileDetailsError(message, url, nodeID));
            if (cb) {
                cb(error);
            }
        });
};

const fetchMediaNodeAccessTokenInProgress = () => {
    return {
        type: MEDIA_NODE_ACCESS_TOKEN_FETCH_IN_PROGRESS,
    };
};

const fetchMediaNodeAccessTokenSuccess = (value) => {
    return {
        type: MEDIA_NODE_ACCESS_TOKEN_FETCH_SUCCESS,
        value,
    };
};

const fetchMediaNodeAccessTokenError = (message) => {
    return {
        type: MEDIA_NODE_ACCESS_TOKEN_FETCH_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchMediaNodeAccessToken = (url, nodeID, cb) => (dispatch) => {
    dispatch(fetchMediaNodeAccessTokenInProgress());

    const URL = urlFetchMediaNodeAccessToken(url);
    const data = {
        refreshToken: localStorage.getItem(`rfToken_of_studio_media_node_${nodeID}`),
    };

    Axios.post(URL, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem(`acToken_of_studio_media_node_${nodeID}`),
        },
    })
        .then((res) => {
            localStorage.setItem(`acToken_of_studio_media_node_${nodeID}`, res.data && res.data.result['access_token']);
            localStorage.setItem(`rfToken_of_studio_media_node_${nodeID}`, res.data && res.data.result['refresh_token']);
            dispatch(fetchMediaNodeAccessTokenSuccess('Success', res.data && res.data.result['access_token']));
            if (cb) {
                cb(null);
            }
        })
        .catch((error) => {
            const message = handleErrorMessage(error);
            dispatch(fetchMediaNodeAccessTokenError(message, url, nodeID));
            if (cb) {
                cb(error);
            }
        });
};

const fetchMediaNodeStatusInProgress = () => {
    return {
        type: MEDIA_NODE_STATUS_FETCH_IN_PROGRESS,
    };
};

export const fetchMediaNodeStatusSuccess = (value, nodeID) => {
    return {
        type: MEDIA_NODE_STATUS_FETCH_SUCCESS,
        value,
        nodeID,
    };
};

const fetchMediaNodeStatusError = (message) => {
    return {
        type: MEDIA_NODE_STATUS_FETCH_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchMediaNodeStatus = (url, nodeID, cb) => (dispatch) => {
    dispatch(fetchMediaNodeStatusInProgress());

    const URL = urlFetchMediaNodeStatus(url);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem(`acToken_of_studio_media_node_${nodeID}`),
        },
    })
        .then((res) => {
            dispatch(fetchMediaNodeStatusSuccess(res.data && res.data.result, nodeID));
            if (cb) {
                cb(null);
            }
        })
        .catch((error) => {
            const message = handleErrorMessage(error);
            dispatch(fetchMediaNodeStatusError(message));
            if (cb) {
                cb(error);
            }
        });
};
