import {
    CREATE_CONTRACT_SUB_TAB_SET,
    CLEAR_CREATE_CONTRACT_DATA,

    COLLECTION_PREVIEW_URL_SET,
    COLLECTION_NAME_SET,
    COLLECTION_SYMBOL_SET,
    COLLECTION_DESCRIPTION_SET,
    COLLECTION_COLLABORATION_STATUS_SET,
    COLLECTION_COLLABORATION_VALUE_SET,
    COLLECTION_SCHEMA_JSON_SET,
    COLLECTION_SCHEME_JSON_TAB_SET,

    MINT_CONFIGURATION_NFT_TITLE_SET,
    MINT_CONFIGURATION_DESCRIPTION_SET,
    MINT_CONFIGURATION_MEDIA_URL_SET,
    MINT_CONFIGURATION_PREVIEW_URL_SET,
    MINT_CONFIGURATION_ADMIN_ADDRESS_SET,
    MINT_CONFIGURATION_PAYMENT_COLLECTOR_SET,
    MINT_CONFIGURATION_TRANSFERABILITY_SWITCH_SET,
    MINT_CONFIGURATION_EXTENSIBILITY_SWITCH_SET,
    MINT_CONFIGURATION_NSFW_SWITCH_SET,
    MINT_CONFIGURATION_ROYALTY_SWITCH_SET,
    MINT_CONFIGURATION_ROYALTY_VALUE_SET,
    MINT_CONFIGURATION_MINT_PER_ADDRESS_SET,
    MINT_CONFIGURATION_TOTAL_SUPPLY_SET,
    MINT_CONFIGURATION_START_TIME_SET,
    MINT_CONFIGURATION_PRICE_PER_MINT_SET,
    MINT_CONFIGURATION_SELECT_TOKEN_SET,
    MINT_CONFIGURATION_MINT_ADMIN_ADDRESS_SET,
    MINT_CONFIGURATION_SCHEMA_VALUES_SET,

    WHITELIST_SETUP_DETAILS_SET,
    WHITELIST_SETUP_ROUND_ADD,
    WHITELIST_ADMIN_ADDRESS_SET,
    WHITELIST_CONTRACT_ADDRESS_SET,

    OEM_CREATION_SUCCESS_DIALOG_HIDE,
    OEM_CREATION_SUCCESS_DIALOG_SHOW,

    FETCH_MINTER_CONTRACT_DETAILS_ERROR,
    FETCH_MINTER_CONTRACT_DETAILS_IN_PROGRESS,
    FETCH_MINTER_CONTRACT_DETAILS_SUCCESS,
} from '../../constants/contracts';
import { combineReducers } from 'redux';
import { tokensList } from '../../utils/defaultOptions';

const createContractSubTab = (state = {
    value: '1',
}, action) => {
    switch (action.type) {
    case CREATE_CONTRACT_SUB_TAB_SET:
        return {
            ...state,
            value: action.value,
        };
    case CLEAR_CREATE_CONTRACT_DATA:
        return {
            ...state,
            value: '1',
        };
    default:
        return state;
    }
};

// Collection Setup
const collectionPreviewUrl = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case COLLECTION_PREVIEW_URL_SET:
        return {
            ...state,
            value: action.value,
        };
    case CLEAR_CREATE_CONTRACT_DATA:
        return {
            ...state,
            value: '',
        };
    default:
        return state;
    }
};

const collectionName = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case COLLECTION_NAME_SET:
        return {
            ...state,
            value: action.value,
            valid: action.valid,
        };
    case CLEAR_CREATE_CONTRACT_DATA:
        return {
            ...state,
            value: '',
            valid: true,
        };
    default:
        return state;
    }
};

const collectionSymbol = (state = {
    value: '',
    valid: false,
}, action) => {
    switch (action.type) {
    case COLLECTION_SYMBOL_SET:
        return {
            ...state,
            value: action.value,
            valid: action.valid,
        };
    case CLEAR_CREATE_CONTRACT_DATA:
        return {
            ...state,
            value: '',
            valid: false,
        };
    default:
        return state;
    }
};

const collectionDescription = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case COLLECTION_DESCRIPTION_SET:
        return {
            ...state,
            value: action.value,
            valid: action.valid,
        };
    case CLEAR_CREATE_CONTRACT_DATA:
        return {
            ...state,
            value: '',
            valid: true,
        };
    default:
        return state;
    }
};

const collectionCollaboration = (state = {
    status: false,
    value: [],
}, action) => {
    switch (action.type) {
    case COLLECTION_COLLABORATION_STATUS_SET: {
        if (action.value && state.value && Object.keys(state.value).length === 0) {
            return {
                status: action.value,
                value: [{
                    address: action.address,
                    weight: '100',
                }],
            };
        } else if (!action.value) {
            return {
                ...state,
                status: action.value,
            };
        } else {
            return {
                ...state,
                status: action.value,
            };
        }
    }
    case COLLECTION_COLLABORATION_VALUE_SET:
        return {
            ...state,
            value: action.value,
        };
    case CLEAR_CREATE_CONTRACT_DATA:
        return {
            ...state,
            status: false,
            value: [],
        };
    default:
        return state;
    }
};

const collectionSchema = (state = {
    jsonSchema: '',
    jsonValid: true,
    tab: 'visual',
}, action) => {
    switch (action.type) {
    case COLLECTION_SCHEMA_JSON_SET:
        return {
            ...state,
            jsonSchema: action.value,
            jsonValid: action.valid,
        };
    case COLLECTION_SCHEME_JSON_TAB_SET:
        return {
            ...state,
            tab: action.value,
        };
    case CLEAR_CREATE_CONTRACT_DATA:
        return {
            ...state,
            jsonSchema: '',
            jsonValid: true,
            tab: 'visual',
        };
    default:
        return state;
    }
};

// Mint Configuration
const mintConfigurationNftTitle = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case MINT_CONFIGURATION_NFT_TITLE_SET:
        return {
            value: action.value,
        };
    case CLEAR_CREATE_CONTRACT_DATA:
        return {
            ...state,
            value: '',
        };
    default:
        return state;
    }
};

const mintConfigurationDescription = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case MINT_CONFIGURATION_DESCRIPTION_SET:
        return {
            ...state,
            value: action.value,
            valid: action.valid,
        };
    case CLEAR_CREATE_CONTRACT_DATA:
        return {
            ...state,
            value: '',
            valid: true,
        };
    default:
        return state;
    }
};

const mintConfigurationMintPerAddress = (state = {
    value: 1,
}, action) => {
    switch (action.type) {
    case MINT_CONFIGURATION_MINT_PER_ADDRESS_SET:
        return {
            value: action.value,
        };
    case CLEAR_CREATE_CONTRACT_DATA:
        return {
            ...state,
            value: 1,
        };
    default:
        return state;
    }
};

const mintConfigurationTotalSupply = (state = {
    value: 1,
}, action) => {
    switch (action.type) {
    case MINT_CONFIGURATION_TOTAL_SUPPLY_SET:
        return {
            value: action.value,
        };
    case CLEAR_CREATE_CONTRACT_DATA:
        return {
            value: 1,
        };
    default:
        return state;
    }
};

const mintConfigurationTransferabilitySwitch = (state = {
    value: true,
}, action) => {
    switch (action.type) {
    case MINT_CONFIGURATION_TRANSFERABILITY_SWITCH_SET:
        return {
            value: action.value,
        };
    case CLEAR_CREATE_CONTRACT_DATA:
        return {
            ...state,
            value: true,
        };
    default:
        return state;
    }
};

const mintConfigurationExtensibilitySwitch = (state = {
    value: true,
}, action) => {
    switch (action.type) {
    case MINT_CONFIGURATION_EXTENSIBILITY_SWITCH_SET:
        return {
            value: action.value,
        };
    case CLEAR_CREATE_CONTRACT_DATA:
        return {
            ...state,
            value: true,
        };
    default:
        return state;
    }
};

const mintConfigurationRoyaltyValue = (state = {
    value: 0,
}, action) => {
    switch (action.type) {
    case MINT_CONFIGURATION_ROYALTY_VALUE_SET:
        return {
            value: action.value,
        };
    case CLEAR_CREATE_CONTRACT_DATA:
        return {
            ...state,
            value: 0,
        };
    default:
        return state;
    }
};

const mintConfigurationNsfwSwitch = (state = {
    value: false,
}, action) => {
    switch (action.type) {
    case MINT_CONFIGURATION_NSFW_SWITCH_SET:
        return {
            value: action.value,
        };
    case CLEAR_CREATE_CONTRACT_DATA:
        return {
            ...state,
            value: false,
        };
    default:
        return state;
    }
};

const mintConfigurationRoyaltySwitch = (state = {
    value: false,
}, action) => {
    switch (action.type) {
    case MINT_CONFIGURATION_ROYALTY_SWITCH_SET:
        return {
            value: action.value,
        };
    case CLEAR_CREATE_CONTRACT_DATA:
        return {
            ...state,
            value: false,
        };
    default:
        return state;
    }
};

const mintConfigurationAdminAddress = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case MINT_CONFIGURATION_ADMIN_ADDRESS_SET:
        return {
            value: action.value,
        };
    case CLEAR_CREATE_CONTRACT_DATA:
        return {
            ...state,
            value: '',
        };
    default:
        return state;
    }
};

const mintConfigurationPaymentCollector = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case MINT_CONFIGURATION_PAYMENT_COLLECTOR_SET:
        return {
            value: action.value,
        };
    case CLEAR_CREATE_CONTRACT_DATA:
        return {
            ...state,
            value: '',
        };
    default:
        return state;
    }
};

const mintConfigurationStartTime = (state = {
    value: null,
    valid: true,
}, action) => {
    switch (action.type) {
    case MINT_CONFIGURATION_START_TIME_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case CLEAR_CREATE_CONTRACT_DATA:
        return {
            ...state,
            value: null,
            valid: true,
        };
    default:
        return state;
    }
};

const mintConfigurationPricePerMint = (state = {
    value: 0,
}, action) => {
    switch (action.type) {
    case MINT_CONFIGURATION_PRICE_PER_MINT_SET:
        return {
            value: action.value,
        };
    case CLEAR_CREATE_CONTRACT_DATA:
        return {
            ...state,
            value: 0,
        };
    default:
        return state;
    }
};

const mintConfigurationSelectTokens = (state = {
    value: tokensList && tokensList[0],
    options: tokensList,
}, action) => {
    switch (action.type) {
    case MINT_CONFIGURATION_SELECT_TOKEN_SET:
        return {
            ...state,
            value: action.value,
        };
    case CLEAR_CREATE_CONTRACT_DATA:
        return {
            ...state,
            value: tokensList && tokensList[0],
            options: tokensList,
        };
    default:
        return state;
    }
};

const mintConfigurationMediaUrl = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case MINT_CONFIGURATION_MEDIA_URL_SET:
        return {
            ...state,
            value: action.value,
        };
    case CLEAR_CREATE_CONTRACT_DATA:
        return {
            ...state,
            value: '',
        };
    default:
        return state;
    }
};

const mintConfigurationPreviewUrl = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case MINT_CONFIGURATION_PREVIEW_URL_SET:
        return {
            ...state,
            value: action.value,
        };
    case CLEAR_CREATE_CONTRACT_DATA:
        return {
            ...state,
            value: '',
        };
    default:
        return state;
    }
};

const mintConfigurationMintAdminAddress = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case MINT_CONFIGURATION_MINT_ADMIN_ADDRESS_SET:
        return {
            ...state,
            value: action.value,
        };
    case CLEAR_CREATE_CONTRACT_DATA:
        return {
            ...state,
            value: '',
        };
    default:
        return state;
    }
};

const mintConfigurationSchemaValues = (state = {
    value: {},
    valid: true,
}, action) => {
    switch (action.type) {
    case MINT_CONFIGURATION_SCHEMA_VALUES_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case CLEAR_CREATE_CONTRACT_DATA:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const oemCreationSuccessDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case OEM_CREATION_SUCCESS_DIALOG_SHOW:
        return {
            ...state,
            value: action.value,
            open: true,
        };
    case OEM_CREATION_SUCCESS_DIALOG_HIDE:
        return {
            ...state,
            value: {},
            open: false,
        };
    case CLEAR_CREATE_CONTRACT_DATA:
        return {
            ...state,
            open: false,
            value: {},
        };
    default:
        return state;
    }
};

// Whitelist Setup
const whitelistSetup = (state = {
    value: [{
        whitelistAccounts: [],
        token: tokensList && tokensList[0],
        price: 0,
        mintLimit: 1,
        startTime: null,
        endTime: null,
    }],
}, action) => {
    switch (action.type) {
    case WHITELIST_SETUP_DETAILS_SET:
        return {
            ...state,
            value: action.value,
        };
    case WHITELIST_SETUP_ROUND_ADD:
        return {
            ...state,
            value: [
                ...state.value,
                {
                    whitelistAccounts: [],
                    token: tokensList && tokensList[0],
                    price: 0,
                    mintLimit: 1,
                    startTime: null,
                    endTime: null,
                },
            ],
        };
    case CLEAR_CREATE_CONTRACT_DATA:
        return {
            ...state,
            value: [{
                whitelistAccounts: [],
                token: tokensList && tokensList[0],
                price: 0,
                mintLimit: 1,
                startTime: null,
                endTime: null,
            }],
        };
    default:
        return state;
    }
};

const whitelistAdminAddress = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case WHITELIST_ADMIN_ADDRESS_SET:
        return {
            ...state,
            value: action.value,
        };
    case CLEAR_CREATE_CONTRACT_DATA:
        return {
            ...state,
            value: '',
        };
    default:
        return state;
    }
};

const whitelistContractAddress = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case WHITELIST_CONTRACT_ADDRESS_SET:
        return {
            ...state,
            value: action.value,
        };
    case CLEAR_CREATE_CONTRACT_DATA:
        return {
            ...state,
            value: '',
        };
    default:
        return state;
    }
};

const contractDetails = (state = {
    value: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case FETCH_MINTER_CONTRACT_DETAILS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_MINTER_CONTRACT_DETAILS_SUCCESS:
        return {
            ...state,
            value: action.value,
            inProgress: false,
        };
    case FETCH_MINTER_CONTRACT_DETAILS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    createContractSubTab,
    collectionPreviewUrl,
    collectionSymbol,
    collectionName,
    collectionDescription,
    collectionCollaboration,
    collectionSchema,
    mintConfigurationNftTitle,
    mintConfigurationDescription,
    mintConfigurationMintPerAddress,
    mintConfigurationTotalSupply,
    mintConfigurationTransferabilitySwitch,
    mintConfigurationExtensibilitySwitch,
    mintConfigurationNsfwSwitch,
    mintConfigurationRoyaltySwitch,
    mintConfigurationRoyaltyValue,
    mintConfigurationAdminAddress,
    mintConfigurationPaymentCollector,
    mintConfigurationStartTime,
    mintConfigurationPricePerMint,
    mintConfigurationSelectTokens,
    mintConfigurationPreviewUrl,
    mintConfigurationMintAdminAddress,
    mintConfigurationMediaUrl,
    oemCreationSuccessDialog,
    whitelistSetup,
    whitelistAdminAddress,
    whitelistContractAddress,
    contractDetails,
    mintConfigurationSchemaValues,
});
