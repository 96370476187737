import {
    RECONNECT_INTERVAL_TIME,
    RECONNECT_MAX_TIME,
    SOCKET_CONNECTION_CLOSE,
    SOCKET_CONNECTION_ERROR,
    SOCKET_CONNECTION_IN_PROGRESS,
    SOCKET_CONNECTION_SUCCESS,
    SOCKET_RECONNECTION_ERROR,
    TIMER_SET,
} from '../constants/mediaNodes/socket';

const socket = (state = {
    connected: false,
    inProgress: false,
    connection: null,
    message: '',
    error: false,
    reconnectTime: 0,
    timer: 0,
    nodeUrl: '',
    address: '',
    nodeID: '',
    liveID: '',
}, action) => {
    switch (action.type) {
    case SOCKET_CONNECTION_ERROR:
        return {
            ...state,
            inProgress: false,
            message: action.message,
            error: true,
            reconnectTime: state.reconnectTime + RECONNECT_INTERVAL_TIME,
            timer: state.reconnectTime + RECONNECT_INTERVAL_TIME,
        };
    case SOCKET_RECONNECTION_ERROR: {
        return {
            ...state,
            reconnectTime: state.reconnectTime > RECONNECT_MAX_TIME
                ? RECONNECT_MAX_TIME
                : state.reconnectTime + RECONNECT_INTERVAL_TIME,
            timer: state.reconnectTime > RECONNECT_MAX_TIME
                ? RECONNECT_MAX_TIME
                : state.reconnectTime + RECONNECT_INTERVAL_TIME,
        };
    }
    case SOCKET_CONNECTION_CLOSE: {
        return {
            ...state,
            message: action.message,
        };
    }
    case SOCKET_CONNECTION_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case SOCKET_CONNECTION_SUCCESS:
        return {
            ...state,
            inProgress: false,
            connected: true,
            connection: action.value,
            reconnectTime: 0,
            timer: 0,
            error: false,
            nodeUrl: action.nodeUrl,
            nodeID: action.nodeID,
            address: action.address,
            liveID: action.liveID,
        };
    case TIMER_SET:
        return {
            ...state,
            timer: state.timer - 1000,
        };
    default:
        return state;
    }
};

export default socket;
