import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import ClassName from 'classnames';
import CircularProgress from './components/CircularProgress';
import withRouter from './components/WithRouter';

const Home = lazy(() => import('./containers/Home').then().catch());
const NavBar = lazy(() => import('./containers/NavBar').then().catch());
const Sidebar = lazy(() => import('./containers/SideBar').then().catch());
const Library = lazy(() => import('./containers/Library').then().catch());
const Dashboard = lazy(() => import('./containers/Dashboard').then().catch());
const Account = lazy(() => import('./containers/Account').then().catch());
const CollectionPage = lazy(() => import('./containers/CollectionPage').then().catch());
const CreateCollection = lazy(() => import('./containers/CreateCollection').then().catch());
const ConnectAccount = lazy(() => import('./containers/ConnectAccount').then().catch());
// const Campaign = lazy(() => import('./containers/Campaign').then().catch());
const InteractiveVideos = lazy(() => import('./containers/InteractiveVideos').then().catch());
const InteractiveVideo = lazy(() => import('./containers/InteractiveVideo').then().catch());
const Channel = lazy(() => import('./containers/Channel').then().catch());
const Contracts = lazy(() => import('./containers/Contracts').then().catch());
const CreateContracts = lazy(() => import('./containers/Contracts/CreateContract').then().catch());

const UserInfo = lazy(() => import('./containers/Channel/UserInfo').then().catch());
const ChannelInfo = lazy(() => import('./containers/Channel/ChannelInfo').then().catch());
const ChannelSettings = lazy(() => import('./containers/Channel/ChannelSettings').then().catch());

const ITCModule = lazy(() => import('./containers/ITCModule').then().catch());
const ITCModuleCreate = lazy(() => import('./containers/ITCModule/Create').then().catch());
const ITCModuleStats = lazy(() => import('./containers/ITCModule/Stats').then().catch());

const Authorization = lazy(() => import('./containers/Authz').then().catch());
const MediaNode = lazy(() => import('./containers/MediaNode').then().catch());
const LiveStream = lazy(() => import('./containers/LiveStream').then().catch());
const LiveStreamTv = lazy(() => import('./containers/LiveStream/Tv').then().catch());
const TvStream = lazy(() => import('./containers/LiveStream/Tv/Stream').then().catch());

const routes = [{
    path: '/',
    component: ConnectAccount,
}];

const protectedRoutes = [{
    path: '/home',
    component: Home,
}, {
    path: '/library',
    component: Library,
}, {
    path: '/dashboard',
    component: Dashboard,
}, {
    path: '/createCollection',
    component: CreateCollection,
}, {
    path: '/createCollection/:collectionID',
    component: CreateCollection,
}, {
    path: '/account',
    component: Account,
}, {
    path: '/library/:collectionID',
    component: CollectionPage,
}, {
    path: '/interactive-videos',
    component: InteractiveVideos,
}, {
    path: '/interactive-videos/create/:collectionID/:NftID',
    component: InteractiveVideo,
}, {
    path: '/interactive-videos/:interactiveVideoID',
    component: InteractiveVideo,
}, {
    path: '/campaign',
    component: ITCModule,
}, {
    path: '/campaign/create',
    component: ITCModuleCreate,
}, {
    path: '/campaign/stats',
    component: ITCModuleStats,
}, {
    path: '/channel',
    component: Channel,
}, {
    path: '/channel/user',
    component: UserInfo,
}, {
    path: '/channel/:id',
    component: ChannelInfo,
}, {
    path: '/channel/settings/:id',
    component: ChannelSettings,
}, {
    path: '/authz',
    component: Authorization,
}, {
    path: '/contracts',
    component: Contracts,
}, {
    path: '/contracts/create',
    component: CreateContracts,
}, {
    path: '/contracts/:contractID',
    component: CreateContracts,
}, {
    path: '/media-node',
    component: MediaNode,
}, {
    path: '/liveStream/:nodeID',
    component: LiveStream,
}, {
    path: '/liveStream/tv',
    component: LiveStreamTv,
}, {
    path: '/liveStream/tv/:streamID',
    component: TvStream,
}];

const Router = (props) => {
    const { location } = props.router;

    const token = localStorage.getItem('acToken_of_studio');

    useEffect(() => {
        const showBackground = location && location.pathname &&
            protectedRoutes.filter((value) => {
                return location.pathname.split('/')[1] === value.path.split('/')[1];
            });

        if (showBackground.length && location.pathname !== '/home') {
            document.body.classList.add('background');
        } else {
            document.body.className = '';
        }
    }, [location]);

    const showNavBar = props.router && props.router.location && props.router.location.pathname &&
        protectedRoutes.filter((value) => {
            return props.router.location.pathname.split('/')[1] === value.path.split('/')[1];
        });

    return (
        <div className="main_content">
            <Suspense fallback={<CircularProgress/>}>
                {showNavBar.length > 0 && token
                    ? <NavBar hideTabs={props.router.location.pathname === '/home'}/>
                    : null}
                <div className={ClassName('content_div',
                    showNavBar.length > 0 && props.router.location.pathname !== '/home' ? 'side_bar_content' : '')}>
                    {showNavBar.length > 0 && props.router.location.pathname !== '/home' && token
                        ? <Sidebar/>
                        : null}
                    <div
                        className="router_content scroll_bar"
                        id="scroll-bar">
                        <Routes>
                            {protectedRoutes.map((route) =>
                                <Route
                                    key={route.path}
                                    exact
                                    element={<route.component/>}
                                    path={route.path}/>,
                            )}
                            {routes.map((route) =>
                                <Route
                                    key={route.path}
                                    exact
                                    element={<route.component/>}
                                    path={route.path}/>,
                            )}
                            <Route
                                exact
                                element={<ConnectAccount/>}
                                path="*"/>
                        </Routes>
                    </div>
                </div>
            </Suspense>
        </div>
    );
};

Router.propTypes = {
    router: PropTypes.shape({
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

export default withRouter(Router);
