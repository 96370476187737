export const LIVE_STREAM_TAB_SET = 'LIVE_STREAM_TAB_SET';

export const LIVE_STREAMS_FETCH_SUCCESS = 'LIVE_STREAMS_FETCH_SUCCESS';
export const LIVE_STREAMS_FETCH_IN_PROGRESS = 'LIVE_STREAMS_FETCH_IN_PROGRESS';
export const LIVE_STREAMS_FETCH_ERROR = 'LIVE_STREAMS_FETCH_ERROR';

export const LIVE_STREAMS_DETAILS_SET = 'LIVE_STREAMS_DETAILS_SET';
export const LIVE_STREAMS_DETAILS_FETCH_SUCCESS = 'LIVE_STREAMS_DETAILS_FETCH_SUCCESS';
export const LIVE_STREAMS_DETAILS_FETCH_IN_PROGRESS = 'LIVE_STREAMS_DETAILS_FETCH_IN_PROGRESS';
export const LIVE_STREAMS_DETAILS_FETCH_ERROR = 'LIVE_STREAMS_DETAILS_FETCH_ERROR';

export const LIVE_STREAMS_STATUS_FETCH_SUCCESS = 'LIVE_STREAMS_STATUS_FETCH_SUCCESS';
export const LIVE_STREAMS_STATUS_FETCH_IN_PROGRESS = 'LIVE_STREAMS_STATUS_FETCH_IN_PROGRESS';
export const LIVE_STREAMS_STATUS_FETCH_ERROR = 'LIVE_STREAMS_STATUS_FETCH_ERROR';

export const LIVE_STREAMS_WATCH_URL_FETCH_SUCCESS = 'LIVE_STREAMS_WATCH_URL_FETCH_SUCCESS';
export const LIVE_STREAMS_WATCH_URL_FETCH_IN_PROGRESS = 'LIVE_STREAMS_WATCH_URL_FETCH_IN_PROGRESS';
export const LIVE_STREAMS_WATCH_URL_FETCH_ERROR = 'LIVE_STREAMS_WATCH_URL_FETCH_ERROR';

export const STREAM_START_SUCCESS = 'STREAM_START_SUCCESS';
export const STREAM_START_IN_PROGRESS = 'STREAM_START_IN_PROGRESS';
export const STREAM_START_ERROR = 'STREAM_START_ERROR';

export const STREAM_STOP_SUCCESS = 'STREAM_STOP_SUCCESS';
export const STREAM_STOP_IN_PROGRESS = 'STREAM_STOP_IN_PROGRESS';
export const STREAM_STOP_ERROR = 'STREAM_STOP_ERROR';

export const INPUT_STREAM_URL_SET = 'INPUT_STREAM_URL_SET';
export const INPUT_STREAM_KEY_SET = 'INPUT_STREAM_KEY_SET';

export const ADD_DESTINATION_SET = 'ADD_DESTINATION_SET';
export const ADD_STREAM_URL_SET = 'ADD_STREAM_URL_SET';
export const ADD_STREAM_KEY_SET = 'ADD_STREAM_KEY_SET';
export const OUTPUT_DESTINATIONS_LIST_SET = 'OUTPUT_DESTINATIONS_LIST_SET';

export const OUTPUT_DESTINATION_DIALOG_SHOW = 'OUTPUT_DESTINATION_DIALOG_SHOW';
export const OUTPUT_DESTINATION_DIALOG_HIDE = 'OUTPUT_DESTINATION_DIALOG_HIDE';

export const LIVE_STREAM_DESTINATION_UPDATE_SUCCESS = 'LIVE_STREAM_DESTINATION_UPDATE_SUCCESS';
export const LIVE_STREAM_DESTINATION_UPDATE_IN_PROGRESS = 'LIVE_STREAM_DESTINATION_UPDATE_IN_PROGRESS';
export const LIVE_STREAM_DESTINATION_UPDATE_ERROR = 'LIVE_STREAM_DESTINATION_UPDATE_ERROR';

export const LIVE_STREAM_TEXT_SET = 'LIVE_STREAM_TEXT_SET';
export const LIVE_STREAM_TEXT_ENABLE_SET = 'LIVE_STREAM_TEXT_ENABLE_SET';
export const LIVE_STREAM_TEXT_ADD_IN_PROGRESS = 'LIVE_STREAM_TEXT_ADD_IN_PROGRESS';
export const LIVE_STREAM_TEXT_ADD_SUCCESS = 'LIVE_STREAM_TEXT_ADD_SUCCESS';
export const LIVE_STREAM_TEXT_ADD_ERROR = 'LIVE_STREAM_TEXT_ADD_ERROR';

export const LIVE_STREAM_DEFAULT_FILE_SET = 'LIVE_STREAM_DEFAULT_FILE_SET';
export const LIVE_STREAM_DEFAULT_FILE_ADD_IN_PROGRESS = 'LIVE_STREAM_DEFAULT_FILE_ADD_IN_PROGRESS';
export const LIVE_STREAM_DEFAULT_FILE_ADD_SUCCESS = 'LIVE_STREAM_DEFAULT_FILE_ADD_SUCCESS';
export const LIVE_STREAM_DEFAULT_FILE_ADD_ERROR = 'LIVE_STREAM_TEXT_ADD_ERROR';
export const LIVE_STREAM_DEFAULT_FILE_ADD_PROGRESS_SET = 'LIVE_STREAM_DEFAULT_FILE_ADD_PROGRESS_SET';
export const LIVE_STREAM_DEFAULT_FILE_ADD_CANCEL_SET = 'LIVE_STREAM_DEFAULT_FILE_ADD_CANCEL_SET';

export const LIVE_STREAM_DEFAULT_FILE_FETCH_IN_PROGRESS = 'LIVE_STREAM_DEFAULT_FILE_FETCH_IN_PROGRESS';
export const LIVE_STREAM_DEFAULT_FILE_FETCH_SUCCESS = 'LIVE_STREAM_DEFAULT_FILE_FETCH_SUCCESS';
export const LIVE_STREAM_DEFAULT_FILE_FETCH_ERROR = 'LIVE_STREAM_TEXT_FETCH_ERROR';
